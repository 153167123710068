import React, { Component } from "react";

export default function(props) {
	let greetings = props.hasOwnProperty("firstName") ? <h1 class="message">Hi, { props.firstName }.</h1> : ""
	let subHead = props.hasOwnProperty("dek") && props.hasOwnProperty("subDek") ? <h2 class="subscriber-only-sub-head__mb">{props.dek}<br/>{props.subDek}</h2> : "";
	return (
		<div class="paywall-content-wrapper subscriber-only">
			<div class="paywall-content full subscriber-only text-center">
				{ greetings }
				<h1 class="message mb">{ props.title }</h1>
				{ subHead }
				<a href={ `${props.cta_target}?ab=${props.abTag}` } class="subscriber-only-btn">
				{ props.cta_text }
				</a>				
				{props.children}
			</div>
		</div>
	)
}
